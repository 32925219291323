import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

import TextField from './TextField';

import PageNames from '../../../utils/PageNames';

import { selectCategory, selectSearchActive, selectSearchCriteria, setSearch, setSearchCriteria } from '../../../store/filtersSlice';
import { getProductsCount } from '../../../store/productsSlice';
import { selectActiveScreen } from '../../../store/appStateSlice';

const SearchDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
    '& .MuiOutlinedInput-root': {
        paddingRight: 0,
        paddingLeft: 0,
    }
}));

export default function Search() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const activeScreen = useSelector(selectActiveScreen);
    const selectedCategory = useSelector(selectCategory);
    const searchCriteria = useSelector(selectSearchCriteria);
    const isSearchActive = useSelector(selectSearchActive);


    const handleChangeSearchCriteria = (event) => {
        const newValue = event.target.value;
        dispatch(setSearchCriteria(newValue));
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            prepareSearch();
        }
    };

    const handleSearchTap = () => {
        prepareSearch();
    };

    const clearSearchTap = () => {
        dispatch(setSearchCriteria(""));
        dispatch(setSearch(false));
        doSearch();
    };

    const prepareSearch = () => {
        if(searchCriteria !== "") {
            dispatch(setSearch(true));
        } else {
            dispatch(setSearch(false));
        }
        
        doSearch(true);
    };

    const doSearch = (trackSearchTerm) => {
        dispatch(getProductsCount({trackSearchTerm}));

        if(activeScreen !== PageNames.PRODUCTS_LIST && searchCriteria !== '') {
            navigate("/items/"+selectedCategory);
        }
    };

    return (
        <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
            <SearchDiv>
                <TextField
                    size="small"
                    name="search"
                    label={t("searchPlaceholder")}
                    id="search"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchCriteria}
                    onChange={handleChangeSearchCriteria}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={handleSearchTap}>
                                <SearchIcon color="primary"/>
                            </IconButton>
                        </InputAdornment>,
                        startAdornment: isSearchActive ? <InputAdornment position="start" >
                            <IconButton onClick={clearSearchTap}>
                                <CancelIcon color="primary"/>
                            </IconButton>
                        </InputAdornment> : null,
                    }}
                />
            </SearchDiv>
        </Box>
    );
}
