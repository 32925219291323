import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from "../api/api";
import i18next from "i18next";

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
const STATUS_SUCCEEDED = 'succeeded';
const STATUS_FAILED = 'failed';

const initialUserData = {
    anrede: '',
    titel: '',
    vorname: '',
    name: '',
    matrikelNr: '',
    studNr: '',

    strasse: '',
    plz: '',
    ort: '',
    land: '',
    email: '',
    mobil: '',
    festnetz: '',
    ruckrufNr: '',

    institution: {id: 0, name: ''},
    institution_id: 0,

    username: '',
    pass: '',
    passwordConfirm: '',
}

const institusionNotDefinedEntry = {id: 0, name: i18next.t("register:label.institution_not_defined")};

const initialState = {
    status: STATUS_IDLE,
    register_status: STATUS_IDLE,
    error: null,

    salutations: [
        "Herr",
        "Frau",
        "Herr/Frau"
    ],
    titles: [
        "",
        "Prof.",
        "Dr.",
        "Dr. Prof."
    ],

    userData: { ...initialUserData },

    institutions: [{id: -1, name: 'aaa'}],

    // Errors
    vornameError: false,
    nameError: false,

    emailError: false,
    mobilError: false,

    usernameError: false,
    passError: false,
    passwordConfirmError: false,

    usernameTaken: false,
    passwordsDoNotMatch: false,
};

export const getInstitutions = createAsyncThunk(
    'shop/getInstitutions',
    async (_noParams, { getState }) => {
        const response = await Api.getInsitutions();
        return response;
    }
)

export const checkUsername = createAsyncThunk(
    'shop/checkUsername',
    async (_noParams, { getState }) => {
        const username = getState().register.userData.username;
        const response = await Api.checkUsername(username);
        return response;
    }
)

export const registerUser = createAsyncThunk(
    'shop/registerUser',
    async (_noParams, { getState }) => {
        const state = getState();
        let registerData = state.register.userData;
        const response = await Api.registerUser(registerData);
        return response;
    }
)

export const slice = createSlice({
    name: 'register',
    initialState,

    reducers: {
        clearRegisterData: (state, action) => {
            state.userData = { ...initialUserData};
            state.vornameError = false;
            state.nameError = false;

            state.emailError = false;
            state.mobilError = false;

            state.usernameError = false;
            state.passError = false;
            state.passwordConfirmError = false;

            state.usernameTaken = false;
            state.passwordsDoNotMatch = false;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setRegisterStatusIdle: (state, action) => {
            state.register_status = STATUS_IDLE;
        },
        setUserData: (state, action) => {
            state.userData[action.payload.field] = action.payload.value;
            // ANDY - 31.03.2023 - when a field is changed also remove it's error
            if(state[action.payload.field+"Error"]) {
                state[action.payload.field+"Error"] = false; 
            }
        },
        setVornameError: (state, action) => {
            state.vornameError = action.payload;
        },
        setNameError: (state, action) => {
            state.nameError = action.payload;
        },
        setEmailError: (state, action) => {
            state.emailError = action.payload;
        },
        setMobilError: (state, action) => {
            state.mobilError = action.payload;
        },
        setUsernameError: (state, action) => {
            state.usernameError = action.payload;
        },
        setPassError: (state, action) => {
            state.passError = action.payload;
        },
        setPasswordConfirmError: (state, action) => {
            state.passwordConfirmError = action.payload;
        },
        setUserInstitution: (state, action) => {
            state.userData.institution = action.payload;
            state.userData.institution_id = state.userData && state.userData.institution ? state.userData.institution.id : 0;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(getInstitutions.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getInstitutions.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;
                state.institutions = [institusionNotDefinedEntry, ...action.payload.institutions];
            })
            .addCase(getInstitutions.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(checkUsername.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(checkUsername.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;
                state.usernameTaken = action.payload.exists;
            })
            .addCase(checkUsername.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(registerUser.pending, (state) => {
                state.register_status = STATUS_LOADING;
            })
                .addCase(registerUser.fulfilled, (state, action) => {
                state.register_status = (action.payload.success ? STATUS_SUCCEEDED : STATUS_FAILED);
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.register_status = STATUS_FAILED;
                state.error = action.payload;
            })
    }
});

export const { 
    clearRegisterData,
    setStatus, 
    setRegisterStatusIdle,

    setUserData,

    setVornameError,
    setNameError,
    setEmailError,
    setMobilError,
    setUsernameError,
    setPassError,
    setPasswordConfirmError,

    setUserInstitution

} = slice.actions;

export const selectIsLoading = state => state.register.status === STATUS_LOADING;

export const selectTitles = state => state.register.titles;
export const selectSalutations = state => state.register.salutations;

export const selectSalutation = state => state.register.userData.anrede;
export const selectTitle = state => state.register.userData.titel;
export const selectVorname = state => state.register.userData.vorname;
export const selectName = state => state.register.userData.name;
export const selectMatrNr = state => state.register.userData.matrikelNr;
export const selectStudNr = state => state.register.userData.studNr;

export const selectStr = state => state.register.userData.strasse;
export const selectPlz = state => state.register.userData.plz;
export const selectOrt = state => state.register.userData.ort;
export const selectCountry = state => state.register.userData.land;
export const selectEmail = state => state.register.userData.email;
export const selectMobil = state => state.register.userData.mobil;
export const selectFestnetz = state => state.register.userData.festnetz;
export const selectRukruf = state => state.register.userData.ruckrufNr;
export const selectUserInstitution = state => state.register.userData.institution;

export const selectUsername = state => state.register.userData.username;
export const selectPassword = state => state.register.userData.pass;
export const selectPasswordConfirm = state => state.register.userData.passwordConfirm;

export const selectInstitutions = state => state.register.institutions;

// Errors
export const selectVornameError = state => state.register.vornameError;
export const selectNameError = state => state.register.nameError;
export const selectEmailError = state => state.register.emailError;
export const selectMobilError = state => state.register.mobilError;
export const selectUsernameError = state => state.register.usernameError;
export const selectUsernameTakenError = state => state.register.usernameTaken;
export const selectPasswordError = state => state.register.passError;
export const selectPasswordConfirmError = state => state.register.passwordConfirmError;
export const selectPasswordsDoNotMatch = state => state.register.userData.pass !== state.register.userData.passwordConfirm;


export const selectRegisterStatus = state => state.register.register_status;
export const selectRegisterSuccessfull = state => state.register.register_status === STATUS_SUCCEEDED;
export const selectRegisterFailed = state => state.register.register_status === STATUS_FAILED;

export default slice.reducer;