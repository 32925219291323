import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deDE } from '@mui/material/locale';

import { isValidColor } from '../../utils/Colors';

const defaultColors = {
    primary: "#70C1B3",
    secondary: "#f9f9f9",
    error: "#EB547C",
    appBarBGColor: "#F5F5F5",
    shopBGColor: "#f9f9f9",
}

const colorsToUse = Object.keys(defaultColors).reduce((acc, key) => {
    acc[key] = window.appStyle && window.appStyle.hasOwnProperty(key) && isValidColor(window.appStyle[key]) ? window.appStyle[key] : defaultColors[key];
    return acc;
}, {});

// console.log(window.appStyle);
// console.log("Using colors: ", colorsToUse);

const defaultFontStack = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
];

const getFontFamily = () => {
    if (window.appStyle?.standardFont) {
        return [window.appStyle.standardFont, ...defaultFontStack];
    }
    if (window.appStyle?.googleFont) {
        const googleFontName = window.appStyle.googleFont.replace(/\+/g, ' ');
        return [googleFontName, ...defaultFontStack];
    }
    return defaultFontStack;
};

const generalTheme = createTheme({
    palette: {
        primary: {
            main: colorsToUse.primary,
        },
        secondary: {
            main: colorsToUse.secondary,
        },
        error: {
            main: colorsToUse.error
        },
        'appBarBGColor': {
            main: colorsToUse.appBarBGColor,
        },
        'shopGrey': {
            background: colorsToUse.shopBGColor,
        },
        'QVWhite': {
            light: '#FAFAFA',
            main: '#FFFFFF',
            // dark: '#FAFAFA',
            // contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        // 'borderlessButtonHoverBGColor': {
        //     main: '#1976D2',
        // }
    },
    typography: {
        fontFamily: getFontFamily().join(','),
        fontSize: 12,
        headline: {
            fontWeight: 500,
            fontSize: 12,
            letterSpacing: 0.01,
        },
        h3: {
            fontWeight: 400,
            fontSize: 46,
            letterSpacing: 0,
        },
        h5: {
            fontWeight: 500,
            fontSize: 18,
            letterSpacing: 0,
        },
        h6: {
            fontWeight: 500,
            fontSize: 18,
            letterSpacing: 0,
        },
        body1: {
            fontWeight: 400,
            fontSize: 14,
            letterSpacing: 0,
        },
        body2: {
            fontWeight: 400,
            fontSize: 12,
            letterSpacing: 0,
        },
    },
    shape: {
        borderRadius: 8
    },
    deDE,
});
const appTheme = createTheme({
    components: {
        // Name of the component
        MuiTypography: {
            root: ({ ownerState, theme }) => ({
                // paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0),
            }),
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: ({ ownerState, theme }) => ({
                    borderColor: theme.palette.primary.main,
                }),
                root: ({ ownerState, theme }) => ({
                    "&:not(.Mui-error):not(.Mui-disabled):not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.main,
                    },
                    "&:not(.Mui-error):not(.Mui-disabled):not(.Mui-focused) .MuiIconButton-root": {
                        color: theme.palette.primary.main,
                    },
                    "&.Mui-error .MuiIconButton-root": {
                        color: theme.palette.error.main,
                    },
                    "&.Mui-disabled .MuiIconButton-root": {
                        color: theme.palette.action.disabled,
                    },
                }),
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                shrink: ({ ownerState, theme }) => ({
                    color: theme.palette.primary.main,
                }),
            }   
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    marginRight: theme.spacing(0.25),
                    marginLeft: theme.spacing(0.25),
                }),
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    borderBottom: "4px solid",
                    borderColor: theme.palette.QVWhite.main,
                }),
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    paddingLeft: theme.spacing(1),
                    minHeight: theme.spacing(9),
                    '@media (min-width: 600px)': {
                        paddingLeft: theme.spacing(1),
                        minHeight: theme.spacing(10),
                    }
                }),
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    backgroundColor: theme.palette.secondary.main,
                    paddingLeft: theme.spacing(0),
                    paddingRight: theme.spacing(0),
                }),
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: ({ theme }) => ({
                    top: "unset",
                    position: "unset",
                    borderRight: "unset",
                    backgroundColor: theme.palette.secondary.main
                }),
                docked: ({ ownerState, theme }) => ({
                    borderRight: "4px solid",
                    borderColor: theme.palette.QVWhite.main,
                })
            }
        },
        MuiCard : {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    borderRadius: theme.shape.borderRadius,
                }),
            }
        },
        MuiCardMedia: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    // borderRadius: theme.shape.borderRadius,
                    borderTopRightRadius: theme.shape.borderRadius-1,
                    borderTopLeftRadius: theme.shape.borderRadius-1,
                }),
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ ownerState, theme }) => ({
                    padding: theme.spacing(0),
                }),
            }
        },
        MuiImageList: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    margin: theme.spacing(0),
                }),
            }
        },
        MuiImageListItem: {
            styleOverrides: {
                img: ({ ownerState, theme }) => ({
                    borderRadius: 1.5 * theme.shape.borderRadius,
                    border: "1px solid",
                    borderColor: theme.palette.secondary.main
                }),
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: ({ ownerState, theme }) => ({
                    minWidth: 300
                }),

            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    height: theme.spacing(14),
                    backgroundColor: theme.palette.appBarBGColor.main
                }),
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    // backgroundColor: theme.palette.appBarBGColor.main
                    paddingBottom: theme.spacing(0),
                    padding: theme.spacing(4)
                }),
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    alignItems: "center"
                }),
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole list item buttons!
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'borderless' },
                    style: {
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        fontSize: 11,
                        letterSpacing: 0,
                        margin: generalTheme.spacing(0),
                        padding: generalTheme.spacing(1.01),
                        color: generalTheme.palette.primary.main,
                        "&:hover": {
                            color: generalTheme.palette.primary.light,
                        }
                    },
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        margin: 1,
                        fontSize: 12,
                        letterSpacing: "0.01px",
                    },
                },
                {
                    props: { variant: 'contained' },
                    style: {
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        margin: 1,
                        fontSize: 12,
                        letterSpacing: "0.01px",
                        boxShadow: "none",
                        backgroundColor: generalTheme.palette.primary.main,
                        color: generalTheme.palette.QVWhite.light,
                        "&:hover": {
                            backgroundColor: generalTheme.palette.primary.light,
                        },
                        "&:active": {
                            backgroundColor: generalTheme.palette.primary.dark,
                        }
                    },
                },
            ],
        },
        
    },
},
generalTheme);

const loadFontSource = () => {
    // load Google Font if specified and no standard font is set
    if (window.appStyle?.googleFont && !window.appStyle?.standardFont) {
        const link = document.createElement('link');
        link.href = `https://fonts.googleapis.com/css2?family=${window.appStyle.googleFont}:wght@400;500&display=swap`;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    }
}

export const AppTheme = ({ children }) => {

    useEffect(() => {
        // Load the font and get cleanup function
        loadFontSource();
    }, []);


    return (
        <ThemeProvider theme={appTheme}>
            {children}
        </ThemeProvider>
    );
};