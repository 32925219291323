import { createContext, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useNavigate,
} from 'react-router-dom';

import { useLocalStorage } from "./useLocalStorage"
import { Storage } from "../utils/Storage";

import { Api } from "../api/api";

import { selectLoginInCheckout, selectLoginUsingIAM, selectSamlLogoutUrl } from "../store/settingsSlice";
import { setShowHelpPopup } from "../store/locationsSlice";
import { emptyCart } from "../store/cartSlice";
import { setLocation } from "../store/filtersSlice";
import { clearProducts } from "../store/productsSlice";
import { removeProduct } from "../store/productSlice";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();

    const loginInCheckout = useSelector(selectLoginInCheckout);
    const loginUsingIAM = useSelector(selectLoginUsingIAM);
    const samlLogoutUrl = useSelector(selectSamlLogoutUrl);

    const navigate = useNavigate();

    const [token, setToken] = useLocalStorage("auth_token", '');

    const doLogin = async (loginData, iam_data = null) => {
        // login data: { username, password }
        const { success, message, token, show_shop_help_popup } = await Api.login(loginData, iam_data);

        if (success) {
            setToken(token);
            dispatch(setShowHelpPopup(show_shop_help_popup));
            Storage.setLoggedIn(token, iam_data !== null, loginInCheckout !== true);
        }
        return { success, message };
    };

    const doLogout = () => {
        setToken("");
        // Empty cart on logout
        dispatch(emptyCart());
        // Clear products
        dispatch(clearProducts());
        // Remove product
        dispatch(removeProduct());
        // Remove the selected location id
        dispatch(setLocation("0"));

        Storage.logoutStorage();

        if(loginUsingIAM) {
            // redirect to shibboleth logout
            window.location.href = samlLogoutUrl;
        } else {
            navigate("/");
        }
    };

    const doIAMLogin = async () => {
        // used to tell the BE to check if the user has been auth by an identity managemet
        // should be the same as in BE
        const TRY_IAM_USER = '__try_iam_user__';
        const IAM_TOKEN = 'IAM_00_p+0:vnn0?VV';

        let success = false;
        let message = '';

        const { iam_data } = await Api.get_iam_data(TRY_IAM_USER, IAM_TOKEN);

        if(iam_data) {
            ({ success, message } = await doLogin({username: TRY_IAM_USER, password: IAM_TOKEN}, iam_data));
        } else {
            message = 'shibboleth_not_allowed';
        }

        return { success, message };
    }

    const value = useMemo(
        () => ({
            token,
            onLogin: doLogin,
            onLogout: doLogout,
            doIAMLogin,
            setToken,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [token, loginInCheckout, loginUsingIAM]
    );

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};