import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from "../api/api";
import { AvailabilityUtils } from './utils/AvailabilityUtils';
import { CartSliceUtils } from './utils/CartSliceUtils';
import { ProductsUtils } from './utils/ProductsUtils';

const PRODUCTS_PER_PAGE = 25;

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
const STATUS_SUCCEEDED = 'succeeded';
const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    error: null,

    availability_status: STATUS_SUCCEEDED,
    availability_error: null,
    products_availability_trigger: new Date().getTime(),

    productsCount: 0,
    products: null,

    specifications: null,
};

export const getProducts = createAsyncThunk(
    'shop/getProducts',
    async ({ page }, { getState }) => {
        const state = getState();
        const category = state.filters.category;
        const location = state.filters.location;

        const isSearch = state.filters.search;
        const searchCriteria = state.filters.searchCriteria;

        const specificationsFilters = state.filters.specificationsFilters;

        const recurring = state.filters.recurring;
        const startDate = recurring ? state.filters.startDateRecurring : state.filters.startDate;
        const endDate = recurring ? state.filters.endDateRecurring : state.filters.endDate;

        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);

        const response = await Api.getProducts(category, startDate, endDate, isSearch, searchCriteria, specificationsFilters, location, cartArticlesTenantId, page, PRODUCTS_PER_PAGE);
        return response;
    }
)

export const getProductsCount = createAsyncThunk(
    'shop/getProductsCount',
    async ({ trackSearchTerm }, { getState }) => {
        const state = getState();
        const category = state.filters.category;
        const location = state.filters.location;

        const isSearch = state.filters.search;
        const searchCriteria = state.filters.searchCriteria;

        const specificationsFilters = state.filters.specificationsFilters;

        const recurring = state.filters.recurring;
        const startDate = recurring ? state.filters.startDateRecurring : state.filters.startDate;
        const endDate = recurring ? state.filters.endDateRecurring : state.filters.endDate;

        const response = await Api.getProductsCount(category, startDate, endDate, isSearch, searchCriteria, specificationsFilters, location, trackSearchTerm);
        return response;
    }
)

export const checkAvailabilityForProducts = createAsyncThunk(
    'shop/checkAvailabilityForProducts',
    async (_noParams, { getState }) => {
        const state = getState();
        const location = state.filters.location;

        const products = state.products.products;
        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);

        const dataForAvailability = AvailabilityUtils.prepareAvailabilityDataForProducts(products, state, {}, false/* do not read availability for each day */);
        const response = await Api.checkAvailabilityForProducts(dataForAvailability, cartArticlesTenantId, location);
        
        return response;
    }
)

export const slice = createSlice({
    name: 'products',
    initialState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        // Called to trigger availability check at your request - not used at this point
        triggerProductsAvailability: (state, action) => {
            state.products_availability_trigger = new Date().getTime();
        },
        clearProducts: (state) => {
            state.products = null;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.products = {...state.products, ...action.payload.articles};
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(getProductsCount.pending, (state) => {
                state.status = STATUS_LOADING;
                state.productsCount = 0;
                state.products = null;
            })
            .addCase(getProductsCount.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.products = null;
                state.productsCount = parseInt(action.payload.articles_count);
                state.specifications = ProductsUtils.prepareAttributes(action.payload.attributes);
            })
            .addCase(getProductsCount.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(checkAvailabilityForProducts.pending, (state) => {
                state.availability_status = STATUS_LOADING;
            })
            .addCase(checkAvailabilityForProducts.fulfilled, (state, action) => {
                state.availability_status = STATUS_SUCCEEDED;

                if(state.products) {
                    for (const [id, product] of Object.entries(state.products)) {
                        state.products[id] = {...product, available: action.payload[product.product_id]['available']}
                    }
                }
            })
            .addCase(checkAvailabilityForProducts.rejected, (state, action) => {
                state.availability_status = STATUS_FAILED;
                state.availability_error = action.payload;
            })
    }
});

export const { setStatus, triggerProductsAvailability, clearProducts } = slice.actions;

export const selectIsLoading = state => state.products.status === STATUS_LOADING;
export const selectStatusApiHasFailed = state => state.products.status === STATUS_FAILED;
export const selectIsLoadingAvailability = state => state.products.availability_status === STATUS_LOADING;
export const selectTriggerProductsAvailability = state => state.products.products_availability_trigger;

export const selectProductsCount = state => state.products.productsCount;
export const selectProducts = state => state.products.products;

export const selectSpecifications = state => state.products.specifications;

export default slice.reducer;