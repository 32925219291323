import dayjs from "dayjs";

import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { styled, alpha, Box } from '@mui/material';
import { LocalizationProvider, DateTimePicker, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import QVActionBarWithTime from './QVActionBarWithTime';

import DateTimeFormatConfig from '../../../utils/DateTimeFormatConfig';
import RecurrenceDays from './recurringIntervalSelector/utils/RecurrenceDays';
import { ShopDatesInterval } from '../../../utils/ShopDatesInterval';

import { 
    selectStartPickerEnabledIntervals, 
    selectEndPickerEnabledIntervals,
    selectStartPickerEnabledHoursForDay,
    selectEndPickerEnabledHoursForDay,
    setFromPickerError,
    setToPickerError
} from '../../../store/filtersSlice';
import { selectWorkingHours, selectSemesterSettings, selectDaysToDisableAfterToday } from '../../../store/settingsSlice';

const PickersDayForAvailability = styled(PickersDay)(({ theme }) => 
    ({
        backgroundColor: 'transparent',
        '&.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.light, 0.5)
        },
        '&.Mui-selected:focus': {
            backgroundColor: alpha(theme.palette.primary.light, 0.5)
        },
        '&.Mui-selected:hover': {
            backgroundColor: alpha(theme.palette.primary.light, 0.5)
        }
    })
)

export default function DateTimePickerWithSlider(props) {
    const dispatch = useDispatch();

    const { label, selectedDate, onChange, minDateTime, isStartDatePicker, withAvailability = false, availabilityCalendar, onMonthChanged, withTime = true, openingTimesForStartDate = false, openingTimesForEndDate = false } = props;

    const startDatePickerEnabledIntervals = useSelector(selectStartPickerEnabledIntervals);
    const endDatePickerEnabledIntervals = useSelector(selectEndPickerEnabledIntervals);

    const startDatePickerEnabledHoursForDay = useSelector(selectStartPickerEnabledHoursForDay);
    const endDatePickerEnabledHoursForDay = useSelector(selectEndPickerEnabledHoursForDay);

    const todayHours = isStartDatePicker ? startDatePickerEnabledHoursForDay : endDatePickerEnabledHoursForDay;
    const openingTimes = isStartDatePicker ? openingTimesForStartDate : openingTimesForEndDate;
    
    const workingHours = useSelector(selectWorkingHours);
    const semesterSettings = useSelector(selectSemesterSettings);
    const daysToDisableAfterToday = useSelector(selectDaysToDisableAfterToday);

    const [isDateAvailable, setIsDateAvailable] = useState(true);
    const [open, setOpen] = useState(false);

    const renderWeekPickerDay = (pickersDayProps) => {
        const date = pickersDayProps.day;

        if (!withAvailability || !availabilityCalendar || shouldDisableDate(date)) {
            return <PickersDay {...pickersDayProps} />;
        }

        const formattedDate = date.format("YYYY-MM-DD");
        const availabilityForDay = availabilityCalendar[formattedDate];

        if(!availabilityForDay) {
            return <PickersDay {...pickersDayProps} />;
        }
        const percentageForDay = availabilityForDay.percentage;
        const stockLeftForDay = availabilityForDay.stock_left;

        let startWeek = date.startOf('week');
        let endWeek = date.endOf('week');
        let startMonth = date.startOf('month');
        let endMonth = date.endOf('month');
        const prevDayDisabled = shouldDisableDate(date.subtract(1, 'd'));
        const nextDayDisabled = shouldDisableDate(date.add(1, 'd'));

        // let dayIsBetween = date.isBetween(startWeek, endWeek, null, '[]');
        let isFirstDayOfWeek = date.isSame(startWeek, 'day');
        let isLastDayOfWeek = date.isSame(endWeek, 'day');
        let isFirstDayOfMonth = date.isSame(startMonth, 'day');
        let isLastDayOfMonth = date.isSame(endMonth, 'day');

        let intervalProps = {
            color: '#FDFDFD'
        };
        if (isFirstDayOfWeek || isFirstDayOfMonth || prevDayDisabled) {
            intervalProps = {
                ...intervalProps,
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            }
        } 
        
        if (isLastDayOfWeek || isLastDayOfMonth || nextDayDisabled) {
            intervalProps = {
                ...intervalProps,
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            }
        }

        // tood: color codes should be defined in the theme, also the vailable quantities per day should come as params
        if (percentageForDay > 75) {
			// $color = 'darkGreen';
            intervalProps.backgroundColor = '#70C1B3';
		} else if (percentageForDay > 50) {
			// $color = 'oliveGreen';
            intervalProps.backgroundColor = '#B3D380';
		} else if (percentageForDay > 25) {
			// $color = 'yellow';
            intervalProps.backgroundColor = '#ECE08C';
		} else if (percentageForDay > 0) {
			// $color = 'darkOrange';
            intervalProps.backgroundColor = '#FEA180';
		} else if (percentageForDay === -1) { //blocked days
			// $color = 'blocked_days';
            intervalProps.backgroundColor = '#70C1B3';
		} else if (percentageForDay === -2) { //white color - fake div
			// $color = 'blocked_days_white';
            intervalProps.backgroundColor = '#70C1B3';
		} else {
			// $color = 'red';
            intervalProps.backgroundColor = '#EB547C';
		}

        return (
            <Box key={"box_"+formattedDate} sx={{ position: 'relative' }}>
                {!pickersDayProps.outsideCurrentMonth &&
                    <Box display="flex"
                        sx={{
                            position: 'absolute',
                            width: "100%",
                            bottom: "-1px",
                            fontSize: "0.50rem",
                            justifyContent: 'center',
                            alignItems: 'center',
                            ...intervalProps
                        }}
                    >
                        { stockLeftForDay }
                    </Box>
                }
                <PickersDayForAvailability {...pickersDayProps} />
            </Box>
        );
    };

    const shouldDisableDate = (dayToCheck) => {
        let shouldDisableDay = true;

        // if x days after today should be disabled, also disable today
        if (daysToDisableAfterToday > 0 && dayToCheck.isSame(dayjs(), 'day')) {
            return shouldDisableDay
        }

        let formattedDayToCheck = DateTimeFormatConfig.formatDateToDateOnlyString(dayToCheck);
        const intervalsInfo = isStartDatePicker ? startDatePickerEnabledIntervals : endDatePickerEnabledIntervals;

        var intervalToCheck = null;

        intervalsInfo.every(interval => {
            if(dayToCheck.isBetween(DateTimeFormatConfig.getDateFromDateOnlyString(interval.from), 
                DateTimeFormatConfig.getDateFromDateOnlyString(interval.to), "d", '[]')) {

                shouldDisableDay = false;
                if(interval.disabledInside.includes(formattedDayToCheck)) {
                    shouldDisableDay = true;
                }

                intervalToCheck = interval.disabledInside;

                return false; // callback to every - stop Search
            }

            return true; // callback to every - stop Search
        });

        if(intervalToCheck && !shouldDisableDay) {
            shouldDisableDay = ShopDatesInterval.isDayInTheNextXAvailableDaysFromTomorrow(dayToCheck, intervalToCheck, daysToDisableAfterToday);
        }
        
        return shouldDisableDay;
    }

    const onTimeChanged = (hours, minutes) => {
        const newValue = selectedDate.hour(hours).minute(minutes);
        onChange(newValue);
    }

    const onDayChanged = (newValue) => {
        onChange(newValue);
    }

    const handleMonthChanged = (newMonth) => {
        if(onMonthChanged) {
            onMonthChanged(newMonth);
        }
    }

    let component = withTime && DateTimeFormatConfig.enableHours() && todayHours && todayHours.length > 0 ? {
        actionBar: QVActionBarWithTime,
        day: renderWeekPickerDay
    } : {
        day: renderWeekPickerDay
    };

    useEffect(() => {
        let isDateAvailable = ShopDatesInterval.isDateAvailable(workingHours, semesterSettings, selectedDate, isStartDatePicker ? RecurrenceDays.WORKING_HOURS_KEY_FOR_PICKUP : RecurrenceDays.WORKING_HOURS_KEY_FOR_DROPOFF, withTime);
        if(isDateAvailable) {
            // check if is should be disabled
            isDateAvailable = !shouldDisableDate(selectedDate);
        }

        // console.log('DateTimePickerWithSlider: useEffect: selectedDate: ', selectedDate, ' isDateAvailable: ', isDateAvailable);
        
        setIsDateAvailable(isDateAvailable);

        if(isStartDatePicker) {
            dispatch(setFromPickerError(!isDateAvailable));
        } else {
            dispatch(setToPickerError(!isDateAvailable));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    const extraSxPropsForTextField = withTime ? {} : { minWidth: '135px' };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DateTimePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                label={label}
                value={selectedDate}
                shouldDisableDate={shouldDisableDate}
                closeOnSelect={false}
                minutesStep={15}
                format={DateTimeFormatConfig.getPickerDateFormat(withTime)}
                inputFormat={DateTimeFormatConfig.getPickerDateFormat(withTime)}
                onChange={onDayChanged}
                onMonthChange={handleMonthChanged}
                minDateTime={minDateTime}
                slots={component}
                slotProps={{
                    actionBar: {
                        selectedDate: selectedDate,
                        minDate: minDateTime,
                        minutes: selectedDate.hour() * 60 + selectedDate.minute(),
                        onTimeChanged: onTimeChanged,
                        isStartDatePicker: isStartDatePicker,
                        openingTimes: openingTimes
                    },
                    textField: {
                        sx: { input: { cursor: 'pointer',  }, ...extraSxPropsForTextField },
                        readOnly: true,
                        error: !isDateAvailable,
                        onClick: (e) => setOpen(!open)
                    },
                    tabs: {
                        hidden: true,
                    },
                }}
                
                views={['day']}
                // the toolbar shows on mobile the edit icon and other controlls, to hide it here is set an empty toolbar
                ToolbarComponent={(props) => (<Fragment></Fragment>)}
            />
        </LocalizationProvider>
    );
};