import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounceCallback } from '@react-hook/debounce';
import { useTranslation } from "react-i18next";

import { Box, Container, Stack, Typography } from '@mui/material';

import { notScrollable, flexColFullHeightScrollable, p1, m1 } from '../common/ui/sx/common';

import { usePageLayoutConfigurator } from '../common/hooks/usePageLayoutConfigurator';
import BackButton from '../common/ui/qvshop/components/core/BackButton';
import PrimaryButton from '../common/ui/qvshop/components/core/PrimaryButton';
import ReturnKeyDialog from '../common/ui/qvshop/components/popups/ReturnKeyDialog';
import * as CartComponents from "./components";
import Breadcrumbs from '../common/ui/qvshop/components/Breadcrumbs';

import Debounce from '../common/utils/Debounce';
import PageNames from '../common/utils/PageNames';
import { NavigationUtils } from '../common/utils/NavigationUtils';

import { 
    addReturnKeyToCart, 
    checkAvailabilityForCart, 
    checkKeyAvailability, 
    removeReturnKeyFromCart, 
    selectCart, 
    selectcartContainsAMandatoryReturnKey, 
    selectCartContainsAReturnKey, 
    selectCartHasAllItemsAvailable, 
    selectCartHasItems, 
    selectCartHasRecurrenceErrors, 
    selectCartHasFromToErrors, 
    selectIsLoadingAvailability, 
    selectReturnKeyData, 
    selectTriggerCartAvailability, 
    selectTriggerReturnKeyDataChanged, 
    setReturnKeyAvailable 
} from '../common/store/cartSlice';
import { selectHasProduct, selectProduct } from '../common/store/productSlice';
import { selectCategory } from '../common/store/filtersSlice';
import { selectPricesEnabled, selectLoginUsingIAM, selectLoginInCheckout } from '../common/store/settingsSlice';

import { CartUtils } from '../common/utils/CartUtils';
import { ProductUtils } from '../common/store/utils/ProductUtils';

export default function Cart() {
    usePageLayoutConfigurator(PageNames.CART);
    const dispatch = useDispatch();
    const { t } = useTranslation(['common', 'cart']);

    const navigate = useNavigate();
    const hasVisitedProductDetailsPage = useSelector(selectHasProduct);
    const lastVisitedProduct = useSelector(selectProduct);
    const selectedCategory = useSelector(selectCategory);

    const loginUsingIAM = useSelector(selectLoginUsingIAM);
    const loginInCheckout = useSelector(selectLoginInCheckout);

    const cart = useSelector(selectCart);
    const cartHasItems = useSelector(selectCartHasItems);
    
    const returnKeyData = useSelector(selectReturnKeyData);
    const cartHasRecurrenceErrors = useSelector(selectCartHasRecurrenceErrors);
    const cartHasFromToErrors = useSelector(selectCartHasFromToErrors);
    const allItemsAvailable = useSelector(selectCartHasAllItemsAvailable);

    const loadingAvailability = useSelector(selectIsLoadingAvailability);
    const triggerAvailability = useSelector(selectTriggerCartAvailability);

    const returnKeyDataChanged = useSelector(selectTriggerReturnKeyDataChanged);

    const cartContainsAReturnKey = useSelector(selectCartContainsAReturnKey);
    const cartContainsAMandatoryReturnKey = useSelector(selectcartContainsAMandatoryReturnKey);

    const pricesEnabled = useSelector(selectPricesEnabled);
   
    const[ showAddReturnKeyPopup, setShowAddReturnKeyPopup ] = useState(false);
    const[ showRemoveReturnKeyPopup, setShowRemoveReturnKeyPopup ] = useState(false);
    
    const debouncedUpdateReturnKeyInCart = useDebounceCallback(
        (returnKeyData) => {
            if(returnKeyData.required || returnKeyData.optional) {
                dispatch(checkKeyAvailability());
                if(returnKeyData.required) {
                    // Add return key to cart
                    dispatch(addReturnKeyToCart());
                } else {
                    if(!cartContainsAReturnKey) {
                        // ANDY - 13.12.2022 - Only if the key is available ask the user if he wants to add the key to the cart
                        if (returnKeyData.available) {
                            setShowAddReturnKeyPopup(true);
                        } else {
                            dispatch(removeReturnKeyFromCart());
                        }
                    } else {
                        // ANDY - 29.03.2023 - There is also the possibility here to have a user added key
                        // After the mandatory key was requested not to be removed from cart
                        // In that case the popup is not shown anymore until the required/optional does not change
                        // Should we show it in that case too? If the popup should show each time the uncomment the next line and comment the if under it
                        // setShowRemoveReturnKeyPopup(true);
                        if(cartContainsAMandatoryReturnKey) {
                            setShowRemoveReturnKeyPopup(true);
                        } else {
                            dispatch(addReturnKeyToCart(true /* added by user */));
                        }
                    }
                }
            } else {
                console.log("Not required - not mandatory - Remove return key from cart!");
                dispatch(removeReturnKeyFromCart());
            }
        },
        // delay in ms
        Debounce.DEBOUNCE_TIMEOUT
    );
    
    useEffect(() => {
        debouncedUpdateReturnKeyInCart(returnKeyData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, returnKeyDataChanged, debouncedUpdateReturnKeyInCart]);

    const debouncedAvailabilityCheck = useDebounceCallback(
        () => {
            dispatch(checkAvailabilityForCart());
        },
        // delay in ms
        Debounce.DEBOUNCE_TIMEOUT
    );
    
    useEffect(() => {
        if(cartHasItems) {
            if(!(cartHasFromToErrors || cartHasRecurrenceErrors)) {
                debouncedAvailabilityCheck();
            }
        } else {
            // Navigate to previous page. What should be the previous page????
            // navigate(-1); - not the right solution - the previous page might be checkout or any other unavailable page(eg. login page)
            // So the better solutin would be to check if the user has been on any product details page before and go there or if not then go to the products list page
            navigate(NavigationUtils.navigateToProductDetailsOrProductsList(hasVisitedProductDetailsPage, lastVisitedProduct, selectedCategory), { replace: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, debouncedAvailabilityCheck, triggerAvailability, cartHasItems]);
    
    const onAddReturnKey = () => {
        dispatch(addReturnKeyToCart(true /* added by user */));
        setShowAddReturnKeyPopup(false);
    }
    
    const onCancelAddReturnKey = () => {
        setShowAddReturnKeyPopup(false);
    }
    
    const onRemoveReturnKey = () => {
        dispatch(removeReturnKeyFromCart());
        // Forcereturn key to available in order to remove the return key out of stock errors if any
        dispatch(setReturnKeyAvailable(true));
        setShowRemoveReturnKeyPopup(false);
    }
    
    const onCancelRemoveReturnKey = () => {
        dispatch(addReturnKeyToCart(true /* added by user */));
        setShowRemoveReturnKeyPopup(false);
    }

    const navigateToCheckout = () => {
        if(loginUsingIAM && loginInCheckout) {
            // this needs to be a redirect. if the user is not logged in, it will be redirected to shibboleth for authentication
            // once is authenticated, the browser will return to auth.php, which in turn will redirect to the checkout page
            window.location.href = "/api/auth.php";
        } else {
            navigate("/checkout");
        }
    }

    let cartPrice = 0;
    
    const showPrice = pricesEnabled;
    if(showPrice) {
        cartPrice = CartUtils.gerPriceForCart(cart);
    }

    return (
        <Container sx={{...flexColFullHeightScrollable, ...notScrollable, ...p1}} component="main" maxWidth="lg">
            <Stack sx={m1} direction="column" spacing={3} >
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <BackButton to={NavigationUtils.navigateToProductDetailsOrProductsList(hasVisitedProductDetailsPage, lastVisitedProduct, selectedCategory)}/>
                        <Box sx={{
                            display: { xs: 'none', sm: 'block' }}}>
                            <Breadcrumbs />
                        </Box>
                    </Stack>

                    { false && <Box >
                        <Typography variant="body1">{t("cart:cart")}</Typography>
                    </Box> }

                    {(showPrice) &&
                        <Stack direction='column' sx={{ml: "auto !important"}}>
                            <Typography variant="body1" color="text.primary">{ProductUtils.formatPrice(cartPrice)} €</Typography>
                            <Typography variant="caption" color="text.primary">{t("vat_included")}</Typography>
                        </Stack>
                    }

                    <PrimaryButton disabled={cartHasFromToErrors || cartHasRecurrenceErrors || !allItemsAvailable} label={t("button.forward")} onClick={navigateToCheckout} />
                </Stack>
                { cart.map(cartItem => cartItem.isReturnKey ? <CartComponents.ReturnKeyCard loadingAvailability={loadingAvailability} key={cartItem.product_id+"_"+cartItem.name} returnKey={cartItem}/> : null) }
                { cart.map(cartItem => !cartItem.isReturnKey ? 
                    (cartItem.isConfigurable && Object.values(cartItem.subarticles).length > 0 
                        ? Object.values(cartItem.subarticles).map(subarticle => <CartComponents.ProductCard key={subarticle.id+"_"+subarticle.gnr_or_prefix} loadingAvailability={loadingAvailability} item={cartItem} subarticle={subarticle} />) 
                        : <CartComponents.ProductCard key={cartItem.product_id+"_"+cartItem.name} loadingAvailability={loadingAvailability} item={cartItem}/> )
                    : null) }
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <PrimaryButton disabled={cartHasFromToErrors || cartHasRecurrenceErrors || !allItemsAvailable} label={t("button.forward")} onClick={navigateToCheckout} />
                </Stack>
            </Stack>
            { showAddReturnKeyPopup && 
                <ReturnKeyDialog 
                    title={t("popups.addReturnKeyTitle")}
                    text1={t("popups.addReturnKeyText1")}
                    text2={t("popups.addReturnKeyText2")}
                    open={showAddReturnKeyPopup} 
                    onOk={onAddReturnKey} 
                    onCancel={onCancelAddReturnKey} 
                /> 
            }
            { showRemoveReturnKeyPopup && 
                <ReturnKeyDialog 
                    title={t("popups.removeReturnKeyTitle")}
                    text1={t("popups.removeReturnKeyText1")}
                    text2={t("popups.removeReturnKeyText2")}
                    open={showRemoveReturnKeyPopup}
                    onOk={onRemoveReturnKey} 
                    onCancel={onCancelRemoveReturnKey} 
                /> 
            }
        </Container>
    );
};