import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from "../api/api";
import { CheckoutUtils } from './utils/CheckoutUtils';

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
const STATUS_SUCCEEDED = 'succeeded';
const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    make_reservation_status: STATUS_IDLE,
    error: null,

    userDetails: null,
    options: null,
    teachers: null,
    cost_centers: null,

    email_lecturer: "",
    usage_text: "",
    teacher: "",
    cost_center_id_for_reservation: "",
    name_lecturer: "",
    course: ""
};

export const getCheckoutOptions = createAsyncThunk(
    'shop/getCheckoutOptions',
    async (_noParams, { getState }) => {
        const cartArticlesIds = getState().cart.cart.map(article => article.product_id);
        const response = await Api.getCheckoutOptions(cartArticlesIds);
        return response;
    }
)

export const getCostCenters = createAsyncThunk(
    'shop/getCostCenters',
    async (prefix) => {
        const response = await Api.getCostCenters(prefix);
        return response;
    }
)

export const makeReservation = createAsyncThunk(
    'shop/makeReservation',
    async (_noParams, { getState }) => {
        const reservationData = CheckoutUtils.prepareDataForReservation(getState());
        const response = await Api.makeReservation(reservationData);
        return response;
    }
)

export const slice = createSlice({
    name: 'checkout',
    initialState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setUserTelephone: (state, action) => {
            state.userDetails.ruckrufnr = action.payload;
        },
        setStudentIdForPickupPerson: (state, action) => {
            state.userDetails.studierenden_nr = action.payload;
        },
        setEmailLecturer: (state, action) => {
            state.email_lecturer = action.payload;
        },
        setNameLecturer: (state, action) => {
            state.name_lecturer = action.payload;
        },
        setCourse: (state, action) => {
            state.course = action.payload;
        },
        setUsageText: (state, action) => {
            state.usage_text = action.payload;
        },
        setTeacher: (state, action) => {
            state.teacher = action.payload;
        },
        resetCostCenters: (state) => {
            state.cost_centers = null;
        },
        setCostCenterIdForReservation: (state, action) => {
            state.cost_center_id_for_reservation = action.payload;
        },
        setMakeResevationStatusIdle: (state, action) => {
            state.make_reservation_status = STATUS_IDLE;
        },
    },

    extraReducers: (builder) => {
        builder
            // GET CHECKOUT OPTIONS
            .addCase(getCheckoutOptions.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getCheckoutOptions.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.userDetails = action.payload.user_details;
                state.options = action.payload.options;
                state.teachers = action.payload.teachers;
            })
            .addCase(getCheckoutOptions.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            // GET COST CENTERS
            .addCase(getCostCenters.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getCostCenters.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;

                state.cost_centers = action.payload.cost_centers;
            })
            .addCase(getCostCenters.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            // MAKE RESEVATION
            .addCase(makeReservation.pending, (state) => {
                state.make_reservation_status = STATUS_LOADING;
            })
            .addCase(makeReservation.fulfilled, (state, action) => {
                state.make_reservation_status = (action.payload.success ? STATUS_SUCCEEDED : STATUS_FAILED);
            })
            .addCase(makeReservation.rejected, (state, action) => {
                state.make_reservation_status = STATUS_FAILED;
                state.error = action.payload;
            })
    }
});

export const { setStatus, setUserTelephone, setStudentIdForPickupPerson,
    setEmailLecturer, setNameLecturer, setCourse,
    setUsageText, setTeacher, setMakeResevationStatusIdle,
    setCostCenterIdForReservation, resetCostCenters } = slice.actions;

export const selectIsLoading = state => state.checkout.status === STATUS_LOADING;

export const selectUserDetails = state => state.checkout.userDetails;
export const selectOptions = state => state.checkout.options;
export const selectTeachers = state => state.checkout.teachers;
export const selectCostCenters = state => state.checkout.cost_centers || [];

export const selectUserTelephone = state => state.checkout.userDetails?.ruckrufnr || "";
export const selectStudentIdForPickupPerson = state => state.checkout.userDetails?.studierenden_nr || "";
export const selectEmailLecturer = state => state.checkout.email_lecturer;
export const selectNameLecturer = state => state.checkout.name_lecturer;
export const selectCourse = state => state.checkout.course;
export const selectUsageText = state => state.checkout.usage_text;
export const selectTeacher = state => state.checkout.teacher;
// export const selectCostCenterIdForReservation = state => state.checkout.cost_center_id_for_reservation;

export const selectMakeReservationStatus = state => state.checkout.make_reservation_status;
export const selectMakeReservationLoading = state => state.checkout.make_reservation_status === STATUS_LOADING;
export const selectMakeReservationSuccessfull = state => state.checkout.make_reservation_status === STATUS_SUCCEEDED;
export const selectMakeReservationFailed = state => state.checkout.make_reservation_status === STATUS_FAILED;

export default slice.reducer;